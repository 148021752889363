/**
 * cookies
 * @name 名称
 * @value 值
 * @options 设置弹窗过期时间
 */
import { Injectable } from '@tanbo/di'
import cookie from 'js-cookie'

@Injectable()
export class CookieService {
  constructor () {}
  getAdjustDialog () {
    return cookie.get('adjustDialog') || null
  }

  setAdjustDialog () {
    cookie.set('adjustDialog', '1', {expires: 7})
  }
}
