
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Tips',
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
});
