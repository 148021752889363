
import { defineAsyncComponent, defineComponent, ref, onBeforeMount } from 'vue';

import { CookieService } from '@/services/cookie.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

const Aside = defineAsyncComponent(() => import('./aside.vue'));
const UserInfo = defineAsyncComponent(() => import('./UserInfo.vue'));

export default defineComponent({
  components: {
    Aside,
    UserInfo,
  },

  setup() {
    const adjustDialogVisible = ref<boolean>(false)
    const injector = useReflectiveInjector([CookieService])
    const cookie = injector.get(CookieService)
    onBeforeMount(() => {
      const adjustDialogCookie = cookie.getAdjustDialog()
      if (!adjustDialogCookie) {
        adjustDialogVisible.value = true
        cookie.setAdjustDialog()
        const adjustTime = setTimeout(() => {
          adjustDialogVisible.value = false
          clearTimeout(adjustTime)
        }, 30000)
      }
    })

    return {
      adjustDialogVisible
    }
  },
});
